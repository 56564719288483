import React, { useState } from 'react';
import RetirementImg from "../images/retirement-graph.PNG";
import { Slider,RangeSlider } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import $ from 'jquery'

function Retirement({onQuery, slidingValue,setSlidingValue}){
  // Calculate the minimum, maximum, and value of the slider based on sliderVal
  const min = 0;
  const max = 150000;
  // const value = parseInt(props.slidingValue) || 0;
  const calculateRetirementCorpus = () =>{ 
    $("#calculate").css('display', 'block');
    onQuery(slidingValue)
  }
  const hidediv = () =>{
    $("#calculate").css('display', 'none');
  }

  return (
    <div className="row py-lg-5 banner-third">
      <div className="col-lg-8 col-md-8 mx-auto text-center">
        <div className="banner-bottom-text text-dark">
          My monthly expenses
          <br />when I retire would be?
        </div>
        <div className='text-center py-5'>
          <div className='mb-2 col-lg-3 m-auto' >
            <input type="text" className='form-controls text-center form-inputs' 
            value={slidingValue} onChange={(e) =>setSlidingValue(e.target.value)} />
          </div>
          <div className='year'>
            per month
          </div>
        </div>
      {/* {console.log(props.sliderVal,"nnnnnnnnnnnnnnnnnn")} */}
        <div>
          <img src={RetirementImg} alt="Retirement" className='img-fluid' width="100%" height="200" />
          <div style={{ display: 'block', width: '100%', paddingLeft: 10 }} className='pb-5 mt-4'>
         
            <Slider
              step={50}
              graduated
              // readOnly
              progress
              min={min}
              value={slidingValue}
              max={max}
              onClick={hidediv}
              onChange={value => {
                setSlidingValue(value)
                //console.log(value);
              }}
              renderMark={(mark) => {
                if ([0, 37500, 75000, 112500, 150000].includes(mark)) {
                  return (
                    <div>
                      <span className='marks-label'>{mark}</span>
                      <div className='marks-lines'></div>
                      <span className="marked-text">{slidingValue}</span>
                    </div>
                  );
                }
                return <span className="marked-text">{slidingValue}</span>;
              }}
            />
          </div>
        </div>

        <div className="mt-5 mb-3">
         <button className='btn-retirement btn'  onClick={calculateRetirementCorpus}>
         Calculate my Retirement Corpus*</button>
        </div>
        <small className='mt-1'>*considering inflation at 5% and return rate at 11%</small>
      </div>
    </div>
  )
}

export default Retirement;
