import React, { useState, useEffect } from "react";
 import '../css/edit_style.css'
import Logo from '../images/logo1.PNG'
import { apiCallingPost } from "../service";

function Login() {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setloginError] = useState("")
    const [loginErrorFlag, setloginErrorFlag] = useState(false)
    const [emailId, setEmailId] = useState("")
    const [showDiv, SetshowDiv] = useState(false)
    const [showLoginDiv, SetLoginDiv] = useState(true)


    const setLoginHandler = (e) => {
        if (userName === "") {
            setloginError("Username required")
            setloginErrorFlag(true)
        }
        else if (password === "") {
            setloginError("Password required")
            setloginErrorFlag(true)
        }
        else {
            setloginErrorFlag(false)
            let postData = {
                "functionName": "login",
                "username": userName,
                "password": password
            }
            apiCallingPost(postData).then((data) => {
                // console.log('getUserAuthentication-- ', data)
                if (data.data.success == "1") { 
                    window.sessionStorage.setItem("user_id", data.data.result.userData[0].userId)
                    window.sessionStorage.setItem("username", data.data.result.userData[0].username)
                    window.sessionStorage.setItem("roleName", data.data.result.userData[0].roleName)
                    if (data.data.result.userData[0].roleName == "Admin" || data.data.result.userData[0].roleName == "SuperAdmin") {
                        window.sessionStorage.setItem("isLogedIn", "1")
                        
                        window.location.href = '/enquire-details'
                    }
                    else {
                        window.location.href = '/login'
                        window.sessionStorage.setItem("isLogedIn", "0")
                    }
                }
                else {
                    // console.log("Username Or Password Is InValid.");
                    setloginError("Username Or Password Is InValid.")
                    setloginErrorFlag(true)
                }
            })
        }
    }
    return (
        <div className='login_wrapper'>
            <div className='left_login'>
                <div className='login_logo'>
                    <img src={Logo} className="img_logo" alt="logo" />
                </div>
                <h1 className='login_header'>Welcome! At  Bajaj Capital</h1>
                {/* <h1 className='login_header'>Welcome to the Himalaya Hyperlocal Content Management System</h1> */}
            </div>
            <div className='right_login'>
                {showLoginDiv &&
                    <div className='right_login_inner'>
                        <h2 className='right_loginhead'>Login</h2>
                        {loginErrorFlag === true &&
                            <label id="loginError" className="inputError"> {loginError} </label>
                        }
                        <div className="form_div">
                            <input
                                type='text'
                                id="username"
                                placeholder="Username"
                                className='form_login'
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                        <div className="form_div">
                            <input
                                type='password'
                                id="password"
                                placeholder="Password"
                                className='form_login'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button
                            type='submit'
                            className='login_btn'
                            onClick={() => setLoginHandler()}
                        >  Login
                        </button>
                        {/* <div className="backto_login">Forgot Password? 
                    <span className="backto_loginspan" 
                        onClick={()=>gotoForget()}
                    > Click here</span>
                    </div> */}
                    </div>
                }

            </div>
        </div>
    );
}

export default Login;
