import React, { useEffect, useState } from 'react';
import RetirementImg from "../images/retirement-graph.PNG"
import { Slider } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";

function RetirementCorpus({query, sliderVal}) {
    const [sliderVal1, setsliderVal1] = useState(5)
    const [sliderVal11, setsliderVal11] = useState(11)
    const [sliderStep, setSliderStep] = useState(1)
    const [sliderStep1, setSliderStep1] = useState(2)
useEffect(() => {
   
}, [sliderVal])
    useEffect(() => {
        if (sliderVal1 !== 0) setSliderStep(sliderVal1)
        if (sliderVal11 !== 0) setSliderStep1(sliderVal11)
    }, [query])
    return (

        <div class="row py-lg-5 banner-third conatiner px-5" id="calculate">
            <div class="col-lg-6 col-md-8 mx-auto text-center">
                <div class="retired-corpus">
                    Retirement Corpus Needed <IoInformationCircleOutline />
                </div>

                <div className='text-center py-5'>
                    <div className='mb-2 col-lg-6 m-auto' >
                        <div className='form-controls text-center form-inputs border-bottom-none'
                        >
                          <span className='rupee-size'><FaRupeeSign /></span>
                            {sliderVal * 342.85 >= 10000000 
                                ? (sliderVal * 342.85 / 10000000).toFixed(2) + " crores" 
                                : (sliderVal * 342.85 / 100000).toFixed(2) + " lakhs"} 
                        </div>
                    </div>
                </div>

                <div>
                    <div className="form-group d-md-flex justify-content-between mb-4">
                        <label htmlFor="" className='col-lg-11 text-start form-label-retire'>Inflation Rate</label>
                        <span><input type="text" className='text-center form-input form-controls col-lg-1' value={sliderVal1 + '%'} /></span>
                    </div>
                    <div style={{
                        display: 'block', width: '100%', paddingLeft: 10
                    }} className='pb-5 mt-4 calculate-slide'>
                        <Slider
                            step={1}
                            graduated
                            readOnly
                            progress
                            min={1}
                            value={sliderStep}
                            max={6}
                            renderMark={(mark) => {
                                if ([1, 2, 3, 4, 5, 6].includes(mark)) {
                                    return (
                                        <div>
                                            <span className='marks-label'>{mark}%</span>
                                            <div className='marks-lines marks-lines-edit'></div>
                                            <span className="marked-text marked-text-edit">{sliderVal1}</span>
                                        </div>
                                    );
                                }
                                return 
                                <span className="marked-text">{sliderVal1}</span>
                                ;
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="form-group d-md-flex justify-content-between mb-4">
                        <label htmlFor="" className='col-lg-11 text-start form-label-retire'>Interest Rate</label>
                        <span><input type="text" className='form-input text-center form-controls col-lg-1' value={sliderVal11 + '%'} /></span>
                    </div>
                    <div style={{
                        display: 'block', width: '100%', paddingLeft: 10
                    }} className='pb-5 mt-4 calculate-slide'>
                        <Slider
                            step={2}
                            graduated
                            readOnly
                            progress
                            min={1}
                            value={sliderStep1}
                            max={15}
                            renderMark={(mark) => {
                                if ([1, 3, 5, 7, 9, 11, 13, 15].includes(mark)) {
                                    return (
                                        <div>
                                            <span className='marks-label'>{mark}%</span>
                                            <div className='marks-lines marks-lines-edit'></div>
                                            <span className="marked-text marked-text-edit">{sliderVal11}</span>
                                        </div>
                                    );
                                }
                                // return <span className="marked-text">{sliderVal1}</span>;
                            }}
                        />
                    </div>
                </div>

                <div className="mt-5 mb-3">
                    <a href='#form_div'> <button className='btn-retirement btn'>Create your plan with an expert</button></a>
                </div>
                <div>
                    <small className='mt-1'>*considering inflation at 5% and return rate at 11%</small>
                </div>

                <div className='mt-4 mb-2'>
                    <small className='mt-3'><IoInformationCircleOutline /> mutual funds are subjected to market risk. Please read all scheme<br /> related documents carefully before investing</small>
                </div>

            </div>
        </div>

    )
}

export default RetirementCorpus;