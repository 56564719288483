import React, { useState, useEffect } from "react";
import AdminHeader from "./admin_header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import * as XLSX from "xlsx";
import { apiCallingPost } from "../service";
function Reports() {
    const [activepage, SetActivepage] = useState(1)
    const [reportList, setReportList] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState('')
    const [pageOffset, setPageOffset] = useState("0")
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isData ,setIsData] = useState(true);
    const [searchData, setSearchData] = useState(false);
    useEffect(() => {
        getReport();
        if(window.sessionStorage.getItem("user_id") === null ) {
            window.location.href = "/login"
        }
    }, [searchData])

    const getReportAllData = () => {
        var dataArray = [];
        for (let i = 0; i < reportList.length; i++) {
            let obj = {};
            obj.submittedOn = formatDate(reportList[i].createdOn);
            obj.name = reportList[i].name;
            obj.emailId = reportList[i].emailId;
            obj.mobileNumber = reportList[i].mobileNumber;
            obj.annualIncome = reportList[i].annualIncome;
            obj.cityName = reportList[i].cityId;
            obj.age = reportList[i].age;
            dataArray.push(obj);

        }
        let header = [
            "Date",
            "Name",
            "Email ID",
            "Mobile No.",
            "Annual Income",
            "City",
            "Age"
        ];
        const columnWidths = [
            { wch: 11 }, // Submitted On
            { wch: 15 }, // Name
            { wch: 20 }, // Email Id
            { wch: 12 }, // Mobile Number
            { wch: 15 }, 
            { wch: 13 }, // City Name
            { wch: 10 }, 
        ];
        const worksheet = XLSX.utils.json_to_sheet(dataArray);

        worksheet["!cols"] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "bajaj-enquiry-report");
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        XLSX.writeFile(workbook, `bajaj-enquiry-report-${new Date(timestamp).toLocaleString('en-GB', { hour12: true }).replaceAll(",", "-").replaceAll("/", ".").replaceAll(":", ".")}.xlsx`, {
            compression: true,
        });
    }
    const pageChange = (pageNumber) => {
        let offset = "0"
        SetActivepage(pageNumber)
        if (pageNumber == "1") {
            offset = "0";
        } else {
            offset = parseInt(pageNumber - 1) * itemsCountPerPage;
        }
        setPageOffset(offset)

        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        if (startDate && endDate) {
            if (startDate > endDate) {
                alert(' End date must be greater than or equal to start date');
                document.getElementById('todate').focus();
                return false;
            }
        }
        const postData = {
            "functionName": "contactUsList",
            "fromDate": startDate ? formatDate(startDate) : '',
            "toDate": endDate ? formatDate(endDate) : '',
            "offset": offset,
            "max": itemsCountPerPage
        }
        //console.log("pageChange---",postData);
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result.contactUsData)
                setTotalItemsCount(data.data.result.totalCount)
            }
            else {
                setReportList([])
                setTotalItemsCount(0)
            }
        })

    };
    const getReport = () => {
        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        if(searchData === true) {
            if (startDate && endDate) {
                if (startDate > endDate) {
                    alert(' End date must be greater than or equal to start date');
                    document.getElementById('todate').focus();
                    return false; 
                }
            }
        }
        let postData = {
            "functionName": "contactUsList",
            "fromDate": startDate ? formatDate(startDate) : '',
            "toDate": endDate ? formatDate(endDate) : '',
            "offset": "0",
            "max": itemsCountPerPage
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result.contactUsData)
                setTotalItemsCount(data.data.result.totalCount)
                setIsData(false);
            }
            else {
                setReportList([])
                setTotalItemsCount(0)
            }
            setSearchData(false)
        })
    }
    const clearSearch = () => {
        window.location.reload();
    };
    const formatDate = (timestamp) => {
        if (timestamp) {
          const date = new Date(timestamp);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString().slice(2);
          const formattedDate = `${day}/${month}/${year}`;
          const hours = date.getHours();
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const amPm = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 === 0 ? 12 : hours % 12;  // Convert to 12-hour format
          const formattedTime = `${formattedHours}:${minutes} ${amPm}`;
          return `${formattedDate} ${formattedTime}`;
        } else {
          return "No Date Available";
        }
      };
    return (
        <div className="dashboard_wrapper">
            <AdminHeader />
            <div className="dash_breadcrumbs">
                <div className="container-fluid px-5">
                    <div className="heading_wrapper_div my-3">
                        <h1 className="view_reportsheading text-trnfrmReport">Enquiry Details</h1>
                        {totalItemsCount > 0 &&
                            <div className=" ms-auto">
                                <span><strong>Total Enquiries :</strong> {totalItemsCount}</span>
                                <button className="ms-4 input_form_bttn"
                                    onClick={getReportAllData}>
                                    Export Excel
                                </button>
                            </div>
                        }
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-2 col-sm-4 mb-2 col-6">
                            <DatePicker
                                className="inputField input-section"
                                selected={startDate}
                                placeholderText="From"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setStartDate(date)}
                                id="fromdate"
                            />
                            
                        </div>
                        <div className="col-md-2  col-sm-4 mb-2 col-6">
                            <DatePicker
                                className="inputField input-section"
                                selected={endDate}
                                placeholderText="To"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setEndDate(date)}
                                id="todate"
                            />
                        </div>
                        <div className="col-md-8">
                            <button onClick={() => {
                                setSearchData(true);
                                getReport();
                            }} className="input_form_bttn"
                            disabled={(startDate && endDate) ? false  : true}>
                                Search
                            </button>
                            <button onClick={clearSearch} className="input_form_bttn ms-2">
                                Clear
                            </button>
                        </div>

                    </div>


                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>
                    {isData === false && (
                    <div className="table-responsive mb-2 report_table_div">
                        {reportList.length > 0 ?
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sl. No.</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Email ID</th>
                                        <th>Mobile No.</th>
                                        <th>City</th>
                                        <th>Annual Income</th>
                                        <th>Age</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {reportList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{(pageOffset * 1) + index + 1}</td>
                                            <td>{formatDate(data.createdOn)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.emailId}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.cityId}</td>
                                            <td>{data.annualIncome}</td>
                                            <td>{data.age}</td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>
                            :
                            <p className="text-danger list-nodata">Sorry, no enquiry details found</p>
                        }
                    </div>
                    )}
                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reports;
