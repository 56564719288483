import React, { useState,useEffect } from 'react';
import "../css/bootstrap.min.css"
import "../css/style.css"
import "../css/responsive.css"
import Logo from "../images/logo1.PNG"

import Banner from "../home/banner"
import Retirement from "../home/retirement-corpus"
import Calculator from "../home/calculate-corpus"
import SatisfiedResults from "../home/satisfied-results"
import Form from "../home/form"
import Bannerbottom from "../home/banner-bottom"
function Home(props) {
    const [sliderVal, setSliderVal] = useState(25000)
    const [query,setQuery]=useState("")
    const [sliderStep, setSliderStep] = useState(50)
    const [result, setResult] = useState(0); // State to store the result after multiplication

    // Function to handle the input change
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const multipliedValue = inputValue * 342.85; // Multiply the input value by 342
        setSliderVal(inputValue); // Update the slider value state
        setResult(multipliedValue); // Set the result state
    };

    useEffect(() => {
        // console.log(props.sliderVal,"sliderValsliderValsliderValsliderVal");
        if (sliderVal !== 0) setSliderStep(sliderVal)
    }, [sliderVal])
  
    const queryChange=(e)=>{
      
        setQuery(query)
    }
    useEffect(() => {      
   }, [query])
    return (
        <main className='container-fluid'>
            <header>
                <div class="navbar">
                    <div class="container py-3">
                        <a href="#" class="navbar-brand d-flex align-items-center">
                            <img src={Logo} alt="Logo" width="250" height="40" className='img-fluid' />
                        </a>
                    </div>
                </div>
            </header>
            <Banner />
            <div class="row py-lg-5 banner-bottom">
                <div class="col-lg-9 col-md-8 mx-auto text-center">
                    <div class="banner-bottom-text text-white">Spend less than 5 minutes knowing<br/> your retirement plan with our
                        <span className='banner-text'> easy to use calculator</span>
                    </div>
                </div>
            </div>
            <Retirement onQuery={queryChange} slidingValue={sliderVal} setSlidingValue={setSliderVal} />
            <Calculator  key={query} query={query} sliderVal={sliderVal}   />
            {/* sliderVal={sliderVal} */}
            {/* <SatisfiedResults />             */}
            <Bannerbottom/>
            <Form />
        </main>
    )
}

export default Home