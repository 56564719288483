import React, { useState } from 'react';
import Bannerimg from "../images/banner-main.PNG"
function Banner() {
    return (
        <div className="banner pt-md-5 position-relative">
            <div className="container row m-auto">
                <div className="col-lg-8  pb-0 pb-md-4">
                    <h1 className='banner-main mb-2'>
                        Your secure financial future
                        is just a few
                        <span className='text-red ms-1 me-1 display-inline'>
                        clicks away.
                        </span>
                        

                    </h1>
                    <p class="col-lg-10 para-text">Start shaping your financial future today with confidence,
                        knowing we’re here to support you every step of the way.</p>
                    <div class="mb-0 mb-md-2">
                        <a href="#form_div" class="btn btn-submit">Know more</a>
                    </div>
                </div>
                <div className="col-lg-4 ">
                    <div className="bannerimg">
                        <img src={Bannerimg} alt="Bannerimg" width="550" height="800" className='img-fluid' />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Banner;