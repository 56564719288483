import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiCallingPost } from "../service";
import Thankyouimg from "../images/thankyou.png"

function Form(props) {

    const [anuualIncomeData, setAnuualIncomeData] = useState([])
    const [age, setAge] = useState('');

    useEffect(() => {
    }, [props]);
    const modalCloseEnquiry = (e) =>{
        $(".enquiry_popup").css('display', "none")
        window.location.reload();
    }
    const formSubmit = (e) => {
        let flag = true
        var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const isValidMobileNumber = /^(?:[1-9][0-9]{9}|0?[1-9][0-9]{9}|\+[1-9][0-9]{11})$/
        //var ageCheck = /^(?:1[01][0-9]|120|1[7-9]|[2-9][0-9])$/;
        let name = $("#fullName").val();
        let email = $("#emailId").val();
        let mobileNo = $("#MobileNumber").val();
        let age = $("#age").val();
        let cityName = $("#cityName").val();
        let incomeValue = $("#incomeValue").val();
        if (name === "") {
            flag = false
            alert("Please enter full name.")
            $("#fullName").focus()
        }
        else if (!email) {
            flag = false
            alert("Please enter email id.")
            $("#emailId").focus()
        }
        else if (characters.test(email) == false) {
            flag = false
            alert("Invalid email Id.")
            $("#emailId").focus()
        }
        else if (!mobileNo) {
            flag = false
            alert("Please enter mobile number.")
            $("#MobileNumber").focus()
            return
        }
        else if (isValidMobileNumber.test(mobileNo) === false) {
            flag = false
            alert("Invalid mobile number.")
            $("#MobileNumber").focus()
        }
        // else if (incomeValue === "") {
        //     flag = false
        //     alert("Please select annual income")
        //     $("#incomeValue").focus()
        // }
        else if ((age.length != 2) ) {
            flag = false
            alert("Please enter age below 100.")
            $("#age").focus()
        }
        else if (flag === true) {
            $("#submit-btn").prop('disabled', true);
            var contactPost = {
                "functionName": "saveContactUs",
                "contactUsId": "",
                "name": name,
                "emailId": email,
                "mobileNumber": mobileNo,
                "annualIncome": anuualIncomeData,
                "reason": "",
                "cityId": cityName,
                "age": age,
                "createdOn": new Date().getTime().toString()
            }
            //console.log("contactPost---",contactPost);
            apiCallingPost(contactPost).then((data) => {
                //console.log("data---", data);
                 if (data.data.success === "1") {
                //     //console.log("data--1-", data);
                     $(".enquiry_popup").css('display', "flex")
                     $("#submit-btn").removeAttr("disabled");
                 }
                 else{
                    $("#submit-btn").removeAttr("disabled");
                 }
            });
        }

    }
    const ageChange = (e) => {
        const inputAge = e.target.value;
        if (!isNaN(inputAge) && inputAge < 100) {
          setAge(inputAge);
        }
    }
    return (
        <div className='container-fluid py-md-5 px-md-5'>
            <div className="yellow-card mb-5">
                Get your hands on a Free Customised Retirement Plan, tailor-made
                by our expert team. Just fill in your details, and it’s all yours!
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3" id='form_div'>
                <label for="username" className="form-labels">Name</label>
                <div className="has-validation">
                    <input type="text" className="form-controls" placeholder="Please enter your full name"
                        required="" id="fullName" />
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3">
                <label for="username" className="form-labels">Email address</label>
                <div className="has-validation">
                    <input type="text" className="form-controls" placeholder="Your email id"
                        required="" id="emailId" />
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3">
                <label for="username" className="form-labels">Mobile number</label>
                <div className="has-validation">
                    <input type="text" className="form-controls" placeholder="Enter your mobile number"
                        required="" maxLength={13} id="MobileNumber"  />
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3">
                <label for="username" className="form-labels">Annual income</label>
                <div className="has-validation">
                    <select className="form-controls" aria-label="Default select example" id="incomeValue"
                        onChange={(e) => { setAnuualIncomeData(e.target.value) }}>
                        <option value={""}>Select your category</option>
                        <option value={"10 - 20 Lakhs"}>0 - 10 Lakhs</option>
                        <option value={"10 - 20 Lakhs"}>10 - 20 Lakhs</option>
                        <option value={"20 - 30 Lakhs"}>20 - 30 Lakhs</option>
                        <option value={"30 - 50 Lakhs"}>30 - 50 Lakhs</option>
                        <option value={"50 Lakhs & above"}>50 Lakhs & above</option>
                    </select>
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3">
                <label for="username" className="form-labels">City</label>
                <div className="has-validation">

                    <input type="text" className="form-controls" placeholder="Choose your city"
                        required="" id="cityName" />
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mx-md-auto py-3">
                <label for="username" className="form-labels">Current Age</label>
                <div className="has-validation">
                    <input type="number" className="form-controls" placeholder="Enter in years"
                        required="" id="age" 
                        value={age} onChange={(e)=>ageChange(e) }/>
                    <div className="invalid-feedback">
                        Your username is required.
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 mx-md-auto py-3 text-center mb-4 mb-md-0">
                <button className='btn btn-submit' id="submit-btn" onClick={(e)=>formSubmit(e)}>Submit</button>
            </div>
            <div className="enquiry_popup">
                <div className="enquiry_innerpopyp">
                    <div className="enquiry_innerbody">
                        <svg className='enquiry_close' stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" onClick={() => modalCloseEnquiry()}stroke-linecap="round" stroke-linejoin="round" class="enquiry_inner_close" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        <div className="">
                            <img src={Thankyouimg} alt="thankyou" className='img-fluid' width="700" height="700" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form;