import React, { useState } from 'react';
import Bannerimg from "../images/bottom-img.png"
function Banner2() {
    return (
        <div className="banner pt-md-5 position-relative banner-bottom row">
            <div className="container-fluid row m-auto">
                <div className="col-lg-6  pb-4 red-banner-text">                   
                    <p class="col-lg-10 para-text text-white retired-text">
                    Whether your goal is 
retirement planning, 
investment growth,
or asset protection, trust 
<span className='banner-text ms-1'>Bajaj Capital</span> to simplify 
the maze of financial planning 
with professionalism and clarity. 
                        </p>                  
                </div>
                <div className="col-lg-6 ">
                    <div className="">
                        <img src={Bannerimg} alt="Bannerimg" width="100%" height="800" className='img-fluid mb-3' />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Banner2;