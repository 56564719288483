
import axios from "axios";

const getDetailsPageWiseURL = 'https://uzy0dh6r6k.execute-api.ap-south-1.amazonaws.com/test'

export function apiCallingPost(postData) {
  return axios.post(getDetailsPageWiseURL, postData)
    .then((data) => data)
    .then((res) => res)
}

